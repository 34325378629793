<template>
  <div id="login-form">
    <h1 class="title has-text-centered">Login</h1>
    <form @submit.prevent="login">
      <b-field label="Email">
        <FormulateInput
          v-model="emailAddress"
          type="email"
          name="email"
          validation="required|email"
          placeholder="first_last@example.com"
        />
      </b-field>

      <b-field label="Password">
        <FormulateInput
          v-model="password"
          type="password"
          name="password"
          placeholder="••••••••••"
          validation="required|min:6,length"
          validation-name="Password"
        />
      </b-field>

      <p v-for="err in errors" :key="err" class="error">
        {{ err }}
      </p>

      <p class="control">
        <button
          id="submitbutton"
          :class="{ 'is-loading': isLoading }"
          class="button is-primary"
        >
          Login
        </button>
      </p>
    </form>
    <router-link
      class="helpful-links button is-info is-inverted"
      to="/signup"
      @click.native="closeModal()"
    >Sign Up Here</router-link
    >
    <router-link
      class="helpful-links button is-info is-inverted"
      to="/reset"
      @click.native="closeModal()"
    >Forget your password?</router-link
    >
  </div>
</template>

<script>
export default {
  name: "Loginform",
  components: {},
  props: {
    payload: {
      type: Object,
      default: null,
    },
  },
  mixins: [],
  data() {
    return {
      isLoading: false,
      emailAddress: null,
      password: null,
      errors: [],
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  computed: {},
  methods: {
    login() {
      const cleanData = this.$accountAPI.cleanData({
        emailAddress: this.emailAddress,
        password: this.password,
      })

      if (cleanData.errors.length >= 1) {
        this.errors = cleanData.errors
        return
      }
      this.errors = []
      this.isLoading = true
      this.$accountAPI
        .login(cleanData)
        .then(() => {
          this.isLoading = false
          this.clearVals()
          this.$buefy.toast.open({
            duration: 2000,
            message: "Login Successful",
            position: "is-top",
            type: "is-success",
          })
          this.$emit("exitModal", cleanData)
          this.$router.push("/dashboard")
        })
        .catch((e) => {
          console.log(e)
          this.isLoading = false
          this.errors = ["Incorrect email or password"]
        })
    },
    clearVals() {
      this.emailErr = null
      this.passErr = null
      this.errors = []
    },
    closeModal() {
      this.$emit("exitModal")
    },
  },
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {
    this.clearVals()
  },
}
</script>

<style lang="scss" scoped>
#login-form {
  margin: 20px;
  width: 450px;
}

#submitbutton {
  margin-top: 9px;
  margin-bottom: 9px;
}

.error {
  color: red;
  font-size: 0.8em;
}

.helpful-links {
  margin-top: 20px;
  margin-right: 10px;
  font-size: 0.9em;
}

@media (max-width: 700px) {
  #login-form {
    margin: 10px;
    width: auto;
  }
}
</style>
